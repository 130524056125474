import { PaymentType } from '@/core';
import { PaymentsState } from '@/redux';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PersonalizationSummaryInfo } from '@/views';

interface Props {
  data: PaymentsState[];
}

export const useGetPaymentsSubtotalAmount = (data: PaymentsState[]) => {
  return useMemo(() => {
    return data
      ? data.reduce(
          (acc, x) =>
            x.paymentType === PaymentType.Fee ||
            x.paymentType === PaymentType.Reimbursement
              ? acc + x.amount
              : acc - x.amount,
          0,
        )
      : data;
  }, [data]);
};

export function PaymentsSubTotalInfo(props: Props) {
  const { data } = props;
  const { t } = useTranslation();

  const subTotalAmount = useGetPaymentsSubtotalAmount(data);

  return (
    <PersonalizationSummaryInfo
      title={t('payments.subTotal')}
      value={subTotalAmount}
    />
  );
}
