import { Col, Divider } from 'antd';
import { FieldRow, Form } from '@/components';
import {
  LANGUAGE_OPTIONS,
  ORIENTATION_OPTIONS,
  SIZE_OPTIONS,
  useReportingHomesLookupSource,
  useReportingProjectsLookupSource,
} from '@/views';
import { useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import { GeneratePersonalizationSummaryReportFormValue } from './GeneratePersonalizationSummaryReportModal';
import { useUserContextSelector } from '@/redux';
import { ReportingClientSelect } from './ReportingClientSelect';
import { ReportingCommunitySelect } from './ReportingCommunitySelect';
import { useTranslation } from 'react-i18next';
import styles from './Reports.PersonalizationSummary.module.scss';

export function PersonalizationSummaryReportFilterForm() {
  const { values, setFieldValue } =
    useFormikContext<GeneratePersonalizationSummaryReportFormValue>();
  const { projectIds: projectId, clientIds, communityIds } = values;
  const { t } = useTranslation();

  const projectsDataSource = useReportingProjectsLookupSource(
    true,
    communityIds,
    clientIds,
  );
  const homesDataSource = useReportingHomesLookupSource(
    undefined,
    projectId,
    true,
  );

  useEffect(() => {
    setFieldValue('homeIds', []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    setFieldValue('projectIds', []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityIds]);

  useEffect(() => {
    setFieldValue('communityIds', []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientIds]);

  const isLetterSize = useMemo(() => values.size === 'Letter', [values]);

  const [isBfp, isClient] = useUserContextSelector((x) => [
    x.isBFP,
    x.isClient,
  ]);

  return (
    <>
      {isBfp && (
        <FieldRow>
          <Col span={24}>
            <ReportingClientSelect name="clientIds" />
          </Col>
        </FieldRow>
      )}
      {(isClient || isBfp) && (
        <FieldRow>
          <Col span={24}>
            <ReportingCommunitySelect
              name="communityIds"
              clientId={clientIds}
            />
          </Col>
        </FieldRow>
      )}

      <FieldRow>
        <Col span={24}>
          <Form.AsyncSelect
            name="projectIds"
            key={[communityIds].concat([clientIds])?.join()}
            dataSource={projectsDataSource}
            mode="multiple"
            required
          />
        </Col>
      </FieldRow>
      <FieldRow>
        <Col span={24}>
          <Form.AsyncSelect
            key={projectId?.join()}
            name="homeIds"
            dataSource={homesDataSource}
            mode="multiple"
            required
            disabled={projectId.length < 1}
          />
        </Col>
      </FieldRow>
      <Divider />
      <Form.Radio name="size" options={SIZE_OPTIONS} inline={false} />
      <Divider />
      {isLetterSize && (
        <>
          <Form.Radio
            name="orientation"
            options={ORIENTATION_OPTIONS}
            inline={false}
          />
          <Divider />
        </>
      )}
      <Form.Radio name="language" options={LANGUAGE_OPTIONS} inline={false} />
      <Divider />
      <Form.Switch
        name="showSignature"
        label={
          <div className={styles.label}>
            {t('personalization.downloadPdf.signature')}
          </div>
        }
        checkedChildren={t('onOff.true')}
        unCheckedChildren={t('onOff.false')}
      />
      {isLetterSize && (
        <Form.Checkbox
          name="showConstruction"
          label={t('personalization.downloadPdf.showConstruction')}
        />
      )}
      <Form.Checkbox
        name="showResidentInfo"
        label={t('personalization.summary.showResidentInfo')}
      />
      <Form.Checkbox
        name="showBaseCost"
        label={t('personalization.summary.showBaseCost')}
      />
      <Form.Checkbox
        name="showResidentPrice"
        label={t('personalization.summary.showResidentPrice')}
      />
    </>
  );
}
