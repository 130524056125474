import { Col, Collapse, Divider, Row, Space, Switch, Typography } from 'antd';
import { groupBy, isEmpty, sortBy } from 'lodash';
import {
  PersonalizationItemState,
  SpecialRequestState,
  useGetPersonalizationItemsQuery,
  useGetSpecialRequestsQuery,
  useUpdatePersonalizationItemMutation,
} from '@/redux';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { array } from '@/utils';
import { CommunityPermissions, PersonalizationItemDto } from '@/core';
import { notify } from '@/components';
import { PersonalizationListItem } from './PersonalizationListItem';
import { PersonalizationItemFormValue } from './Item.Details/PersonalizationItemDetailsModal';
import styles from './PersonalizationListItem.module.scss';
import { SpecialRequestListItem } from './SpecialRequest';
import { useCommunityContext } from '../Communities.Common';

const { Panel } = Collapse;
const { Text } = Typography;

interface Props {
  homeId: string;
  projectId: string;
  stepId?: string;
  roomId?: string;
  readonly?: boolean;
  className?: string;
  onDelete: (id: string) => any;
  compact?: boolean;
  collapseClassName?: string;
}

function useItemGroupsData(props: Props) {
  const { homeId, projectId, stepId, roomId } = props;

  const { data: items = array.empty<PersonalizationItemDto>() } =
    useGetPersonalizationItemsQuery({
      homeId,
      projectId,
      stepId,
      roomId,
      isFloorplan: true,
    });

  const grouped = groupBy(items, (x) => x.category.root.id);
  const itemGroups = Object.keys(grouped).map((id) => ({
    id,
    name: grouped[id][0].category.root.name,
    items: grouped[id],
  }));

  return sortBy(itemGroups, (x) => x.name);
}

const useUpdate = () => {
  const [update] = useUpdatePersonalizationItemMutation();

  return (args: PersonalizationItemFormValue, item: PersonalizationItemState) =>
    update({ ...args, id: item!.id })
      .unwrap()
      .then(() =>
        notify.success.t('personalization.item.details.detailsSaved'),
      );
};

export function PersonalizationList(props: Props) {
  const {
    readonly,
    onDelete,
    homeId,
    projectId,
    stepId,
    className,
    compact,
    collapseClassName,
  } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'personalization.item.details',
  });
  const itemGroups = useItemGroupsData(props);
  const { data: specialRequests = array.empty<SpecialRequestState>() } =
    useGetSpecialRequestsQuery({
      projectId,
      homeId,
      stepId,
      isFloorplan: true,
    });

  const handleSave = useUpdate();

  const specialRequestsKey = 'special-requests' + homeId;

  const [openItems, setOpenItems] = useState<string[]>([]);
  const expanded = openItems.length > 0;

  function onToggleSwitch() {
    const openItemsKeys = !expanded
      ? [...itemGroups.map((x) => x.id), specialRequestsKey]
      : [];
    setOpenItems(openItemsKeys);
  }

  const onChange = (key: string | string[]) => {
    setOpenItems(key as string[]);
  };

  const { assert } = useCommunityContext();

  if (isEmpty(itemGroups) && isEmpty(specialRequests)) {
    return null;
  }

  return (
    <>
      <Row justify="end" className={collapseClassName}>
        <Col>
          <Space className={className}>
            <Switch checked={!expanded} onChange={onToggleSwitch} />
            {t('collapsed')}
          </Space>
        </Col>
      </Row>
      <Collapse
        ghost
        activeKey={openItems}
        onChange={onChange}
        className={styles.cardHeader}
      >
        {itemGroups.map(({ id, name, items }) => (
          <Panel
            key={id}
            header={
              <Space>
                <Text strong>{name}:</Text>
                <Text type="secondary">
                  {t('subheading', { count: items.length })}
                </Text>
              </Space>
            }
          >
            {items.map((item, index) => (
              <>
                <PersonalizationListItem
                  readonly={
                    readonly ||
                    !assert(CommunityPermissions.Personalization.Manage, true)
                  }
                  item={item}
                  key={item.id}
                  className="mb-4"
                  onDelete={onDelete}
                  onSubmit={handleSave}
                  compact={compact}
                />
                {index !== items.length - 1 && <Divider />}
              </>
            ))}
          </Panel>
        ))}
        {!isEmpty(specialRequests) && (
          <Panel
            key={specialRequestsKey}
            header={
              <Space>
                <Text strong>{t('specialRequests')}:</Text>
                <Text type="secondary">
                  {t('subheading', {
                    count: specialRequests.length,
                  })}
                </Text>
              </Space>
            }
          >
            {specialRequests.map((item, index) => (
              <>
                <SpecialRequestListItem
                  item={item}
                  className="mb-4"
                  readonly={
                    readonly ||
                    !assert(
                      CommunityPermissions.Personalization
                        .SpecialRequestComment,
                      true,
                    )
                  }
                  compact={compact}
                />
                {index !== specialRequests.length - 1 && <Divider />}
              </>
            ))}
          </Panel>
        )}
      </Collapse>
    </>
  );
}
