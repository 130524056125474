import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface ResidentsCheckBoxProps {
  showResidents: boolean;
  onShowResidentsChange: (e: CheckboxChangeEvent) => void;
  className?: string;
}

export function useResidentsCheckbox(): ResidentsCheckBoxProps {
  const [showResidents, setShowResidents] = useState(false);

  const onShowResidentsChange = useCallback(
    (e: CheckboxChangeEvent) => setShowResidents(e.target.checked),
    [],
  );

  return {
    showResidents,
    onShowResidentsChange,
  };
}

export const ResidentsCheckbox = (props: ResidentsCheckBoxProps) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'personalization.summary',
  });

  const { showResidents, onShowResidentsChange, className } = props;
  return (
    <Checkbox
      className={className}
      onChange={onShowResidentsChange}
      checked={showResidents}
    >
      {t('showResidentInfo')}
    </Checkbox>
  );
};
