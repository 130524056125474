import { Form, PdfDownloadButton } from '@/components';
import { Button, Divider, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { useFormikContext } from 'formik';
import {
  DownloadPdfButtonProps,
  useDownloadPdf,
  useDownloadTabloidPdf,
  DownloadPdfArgs,
} from './DownloadPdfButton';
import i18next from 'i18next';
import { CommunityPermissions, Language } from '@/core';
import { useCategoryLookupSource } from '../Categories.Common';
import { ResidentsCheckbox, useResidentsCheckbox } from './ResidentsCheckbox';
import styles from './PersonalizationSummary.module.scss';
import { useCommunityContext } from '../Communities.Common';

interface PdfModalProps extends DownloadPdfButtonProps {
  open: boolean;
  toggleModalOpen: () => any;
}

export const ORIENTATION_OPTIONS = [
  {
    value: 'Portrait',
    label: i18next.t<string>(
      'personalization.downloadPdf.orientation.portrait',
    ),
  },
  {
    value: 'Landscape',
    label: i18next.t<string>(
      'personalization.downloadPdf.orientation.landscape',
    ),
  },
];

export const SIZE_OPTIONS = [
  {
    value: 'Letter',
    label: i18next.t<string>('personalization.downloadPdf.size.letter'),
  },
  {
    value: 'Tabloid',
    label: i18next.t<string>('personalization.downloadPdf.size.tabloid'),
  },
];

export const LANGUAGE_OPTIONS = [
  {
    value: Language.English,
    label: i18next.t<string>('enums.Language.English'),
  },
  {
    value: Language.Spanish,
    label: i18next.t<string>('enums.Language.Spanish'),
  },
];

export function DownloadPdfFormModal(props: PdfModalProps) {
  const {
    open,
    toggleModalOpen,
    projectId,
    homeId,
    selectedStepId,
    communityId,
  } = props;
  const { t } = useTranslation();
  const residentsCheckBox = useResidentsCheckbox();
  const { showResidents } = residentsCheckBox;
  const { hasPermission } = useCommunityContext();
  const { values, resetForm } = useFormikContext<DownloadPdfArgs>();
  const isLetterSize = useMemo(() => values.size === 'Letter', [values]);

  const downloadPdf = useDownloadPdf(
    projectId,
    homeId,
    values.showBaseCost,
    values.showResidentPrice,
    showResidents,
    values.orientation,
    values.language,
    values.showConstruction,
    values.showSignature,
    selectedStepId,
    values.categoryIds,
  );

  const downloadTabloidPdf = useDownloadTabloidPdf(
    projectId,
    homeId,
    values.language,
    showResidents,
    selectedStepId,
    values.categoryIds,
  );

  const onCancel = () => {
    resetForm();
    toggleModalOpen();
  };

  const categoriesDataSource = useCategoryLookupSource({
    communityId,
    listed: true,
  });

  return (
    <Modal
      title={t('downloadPdf')}
      visible={open}
      footer={[
        <Button key="back" type="default" onClick={onCancel}>
          {t('cancel')}
        </Button>,
        <PdfDownloadButton
          action={isLetterSize ? downloadPdf : downloadTabloidPdf}
        />,
      ]}
      onCancel={onCancel}
    >
      <Form.AsyncSelect
        dataSource={categoriesDataSource}
        name="categoryIds"
        label={t('personalization.downloadPdf.categoryIds')}
        mode="multiple"
      />
      <Divider />
      <Form.Radio
        name="size"
        options={SIZE_OPTIONS}
        label={t('personalization.downloadPdf.size.title')}
        inline={false}
      />
      <Divider />
      {isLetterSize && (
        <>
          <Form.Radio
            name="orientation"
            options={ORIENTATION_OPTIONS}
            label={t('personalization.downloadPdf.orientation.title')}
            inline={false}
          />
          <Divider />
        </>
      )}
      <Form.Radio
        name="language"
        options={LANGUAGE_OPTIONS}
        label={t('personalization.downloadPdf.language')}
        inline={false}
      />
      <Divider />
      <Form.Switch
        name="showSignature"
        label={
          <div className={styles.label}>
            {t('personalization.downloadPdf.signature')}
          </div>
        }
        checkedChildren={t('onOff.true')}
        unCheckedChildren={t('onOff.false')}
      />
      {isLetterSize && (
        <>
          <Form.Checkbox
            name="showConstruction"
            label={t('personalization.downloadPdf.showConstruction')}
          />
        </>
      )}
      <ResidentsCheckbox className={styles.label} {...residentsCheckBox} />
      {hasPermission(CommunityPermissions.BaseCost.FullAccess) && (
        <Form.Checkbox
          name="showBaseCost"
          label={t('personalization.summary.showBaseCost')}
          className="mt-4"
        />
      )}
      <Form.Checkbox
        name="showResidentPrice"
        label={t('personalization.summary.showResidentPrice')}
      />
    </Modal>
  );
}
