import { useField } from 'formik';
import { FormsGroup } from './FormsGroup';
import { useFormLabel } from './FormsI18nContext';
import { Space, Switch } from 'antd';

export type FormsSwitchProps = {
  name: string;
  label?: React.ReactNode;
  required?: boolean;
  noValidation?: boolean;
  checkedChildren?: React.ReactNode;
  unCheckedChildren?: React.ReactNode;
};

export const FormsSwitch = ({
  name,
  label: labelOverride,
  required,
  noValidation,
  checkedChildren,
  unCheckedChildren,
}: FormsSwitchProps) => {
  const [field, , helpers] = useField(name);
  const label = useFormLabel(name, labelOverride);

  function handleChange(value: boolean) {
    helpers.setValue(value || false);
    helpers.setTouched(true);
  }

  return (
    <FormsGroup name={name} required={required} noValidation={noValidation}>
      <Space direction="horizontal">
        <Switch
          checked={field.value === true}
          onChange={handleChange}
          checkedChildren={checkedChildren}
          unCheckedChildren={unCheckedChildren}
        />
        {label}
      </Space>
    </FormsGroup>
  );
};
