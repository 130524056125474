import { Auth, FieldRow } from '@/components';
import { CustomReportColumn, CustomReportType } from '@/core';
import { Col, Divider, Space, Switch, Typography } from 'antd';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { AdditionalOptionCheckBoxGroup } from './AdditionalOptionCheckBoxGroup';
import {
  useCustomReportAvailableColumns,
  useForceSelectedColumns,
} from './customReportColumnsConfigurations';
import { useMemo } from 'react';

interface Props {
  name: string;
  className?: string;
  excelTemplateAttachmentId?: string;
  reportType: CustomReportType;
}

export function FormCustomReportColumns(props: Props) {
  const { name, className, excelTemplateAttachmentId, reportType } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'reports.custom.details.columns',
  });
  const [{ value }, , { setValue }] = useField<CustomReportColumn[]>(name);

  const availableForSelectionValues = useCustomReportAvailableColumns(
    excelTemplateAttachmentId,
  );
  const forceSelectedValues = useForceSelectedColumns(reportType);

  const selectedAll = value.length === availableForSelectionValues.length;
  function onToggleSwitch() {
    setValue(
      !selectedAll
        ? [...availableForSelectionValues]
        : [...forceSelectedValues],
    );
  }

  const enabledValues = useMemo(
    () =>
      availableForSelectionValues.filter(
        (x) => !forceSelectedValues.includes(x),
      ),
    [availableForSelectionValues, forceSelectedValues],
  );

  return (
    <>
      <Space className="mb-2" direction="vertical" size="small">
        <Typography.Title level={4}>{t('title')}</Typography.Title>
        <Space direction="horizontal">
          <Switch checked={selectedAll} onChange={onToggleSwitch} />
          {t('selectAllColumns')}
        </Space>
      </Space>

      <FieldRow className={className}>
        <Auth role="BFP_ADMIN">
          <Col span={8}>
            <Typography.Title level={5}>
              {t('clientOptionsTitle')}
            </Typography.Title>
          </Col>
        </Auth>
        <Col span={8}>
          <Typography.Title level={5}>
            {t('communityOptionsTitle')}
          </Typography.Title>
        </Col>
        <Col span={8}>
          <Typography.Title level={5}>
            {t('projectOptionsTitle')}
          </Typography.Title>
        </Col>
      </FieldRow>

      <Divider className="m-0" />

      <FieldRow className={className}>
        <Auth role="BFP_ADMIN">
          <Col span={8}>
            <AdditionalOptionCheckBoxGroup
              fieldName={name}
              valueType={CustomReportColumn}
              values={['ClientName', 'ClientId']}
              enabledValues={enabledValues}
            />
          </Col>
        </Auth>

        <Col span={8}>
          <AdditionalOptionCheckBoxGroup
            fieldName={name}
            valueType={CustomReportColumn}
            values={['CommunityName', 'CommunityId', 'CommunityStatus']}
            enabledValues={enabledValues}
          />
        </Col>
        <Col span={8}>
          <AdditionalOptionCheckBoxGroup
            fieldName={name}
            valueType={CustomReportColumn}
            values={[
              'ProjectName',
              'ProjectId',
              'ProjectType',
              'ProjectStatus',
              'PathStepName',
            ]}
            enabledValues={enabledValues}
          />
        </Col>
      </FieldRow>

      <FieldRow className={className}>
        <Col span={8}>
          <Typography.Title level={5}>{t('homeOptionsTitle')}</Typography.Title>
        </Col>
        <Col span={16}>
          <Typography.Title level={5}>{t('itemOptionsTitle')}</Typography.Title>
        </Col>
      </FieldRow>

      <Divider className="m-0" />

      <FieldRow className={className}>
        <Col span={8}>
          <AdditionalOptionCheckBoxGroup
            fieldName={name}
            valueType={CustomReportColumn}
            values={[
              'HomeName',
              'HomeNumber',
              'LocationName',
              'ResidentNames',
              'FloorplanName',
              'ConstructionName',
              'FloorplanType',
              'FloorplanDescription',
              'TotalSquareFoot',
              'RoomName',
              'MenuName',
              'ItemCount',
              'Fee',
              'Credit',
              'Payment',
              'Reimbursement',
              'PaymentTotal',
              'Balance',
            ]}
            enabledValues={enabledValues}
          />
        </Col>
        <Col span={8}>
          <AdditionalOptionCheckBoxGroup
            fieldName={name}
            valueType={CustomReportColumn}
            values={[
              'ItemName',
              'ItemDescription',
              'ConstructionDescription',
              'Category',
              'Subcategory1',
              'Subcategory2',
              'IsFinalized',
              'ItemSubOption',
              'ItemNotes',
              'SpecialRequestComment',
              'ItemSymbol',
              'SpecialRequestStatus',
              'SpecialRequestNotes',
              'SpecialRequestFee',
            ]}
            enabledValues={enabledValues}
          />
        </Col>
        <Col span={8}>
          <AdditionalOptionCheckBoxGroup
            fieldName={name}
            valueType={CustomReportColumn}
            values={[
              'SquareFootage',
              'SpecialRequestDate',
              'Subcontractor',
              'OptionCategory',
              'BaseCost',
              'OwnerPrice',
              'ResidentPrice',
              'SubCost',
              'TbdPrice',
              'ConstructionMarkup',
              'ResidentMarkup',
            ]}
            enabledValues={enabledValues}
          />
        </Col>
      </FieldRow>
    </>
  );
}
