import { Divider, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  PersonalizationItemState,
  ProjectStepLookupState,
  useAddPersonalizationItemMutation,
  useDeletePersonalizationItemMutation,
  useGetProjectHomeInfoQuery,
  useLazyGetHasAnyListedQuery,
  useUpdateFloorplanPersonalizationNeededStateMutation,
  useUpdatePersonalizationItemMutation,
} from '@/redux';
import { confirmation, notify } from '@/components';
import {
  AddSpecialRequestButton,
  FloorplanGeoPersonalizationPanel,
  useCommunityContext,
  useDeleteSpecialRequest,
  useProjectContext,
} from '@/views';
import {
  AddPersonalizationItemButton,
  PersonalizationItemFormValue,
  useEditPersonalizationItemAction,
} from '@/views/Personalization.Details/Item.Details';
import { useCallback } from 'react';
import { CommunityPermissions, ProjectStepLookupDto } from '@/core';
import { useEditSpecialRequestAction } from './SpecialRequest/EditSpecialRequestButton';
import { StepPersonalizationList } from './StepPersonalizationList';

interface Props {
  projectId: string;
  homeId: string;
  communityId: string;
  compact?: boolean;
  steps?: ProjectStepLookupDto[] | null;
}

export const useUpdateFloorplanPersonalizationNeededState = (props: Props) => {
  const [update] = useUpdateFloorplanPersonalizationNeededStateMutation();
  const { homeId, projectId } = props;
  const { t } = useTranslation();
  const [hasAnyListed] = useLazyGetHasAnyListedQuery();

  return useCallback(
    async (currentValue: boolean, stepId?: string) => {
      const perform = () =>
        update({ homeId, projectId, value: !currentValue, stepId })
          .unwrap()
          .then(() => notify.success.t('personalization.updated'));

      const { data: hasValues } = await hasAnyListed({
        projectId,
        homeId,
        stepId,
        isFloorplan: true,
        isGeo: true,
      });

      if (hasValues) {
        confirmation.show({
          title: t('confirmation.delete.title'),
          body: t('personalization.clearConfirmation'),
          hideCancelButton: true,
        });
      } else {
        perform();
      }
    },
    [hasAnyListed, projectId, homeId, update, t],
  );
};

const useUpdate = () => {
  const [update] = useUpdatePersonalizationItemMutation();

  return (args: PersonalizationItemFormValue, item: PersonalizationItemState) =>
    update({ ...args, id: item!.id })
      .unwrap()
      .then(() =>
        notify.success.t('personalization.item.details.detailsSaved'),
      );
};

export const useFloorplanAdd = (
  homeId: string,
  stepId: string | undefined,
  projectId: string,
) => {
  const [add] = useAddPersonalizationItemMutation();
  return (args: PersonalizationItemFormValue) => {
    add({
      ...args,
      stepId: stepId!,
      homeId,
      position: { x: 10, y: 10 },
      projectId,
      isFloorplan: true,
    })
      .unwrap()
      .then(() => notify.success.t('personalization.item.details.saved'));
  };
};

export const useDelete = () => {
  const [deleteItem] = useDeletePersonalizationItemMutation();

  return (id: string) => {
    deleteItem({ id })
      .unwrap()
      .then(() => notify.success.t('personalization.item.details.deleted'));
  };
};

export const useStepDeadlinePassed = (
  homeId: string,
  projectId: string,
  selectedStep?: ProjectStepLookupState,
) => {
  const { data: context } = useGetProjectHomeInfoQuery({
    id: projectId,
    homeId,
  });

  if (!selectedStep || !context) return null;
  return context.steps.find((x) => x.stepId === selectedStep.id)
    ?.isDeadlinePassed;
};

export function FloorplanPersonalizationPanel(props: Props) {
  const { homeId, projectId, communityId, compact, steps } = props;
  const { assert } = useCommunityContext();
  const { isActive } = useProjectContext();

  const handleSpecialRequestDelete = useDeleteSpecialRequest();
  const handleDelete = useDelete();
  const { modal: editItemModal, open: openItemEdit } =
    useEditPersonalizationItemAction(useUpdate());
  const { modal: editSpecialRequestModal, open: openSpecialRequestEdit } =
    useEditSpecialRequestAction();

  const readonly = !steps || !isActive;

  const itemManagementRestricted =
    readonly || !assert(CommunityPermissions.Personalization.Manage, true);

  if (steps === null) {
    return <></>;
  }

  return (
    <>
      {!itemManagementRestricted && (
        <Row>
          <Space wrap>
            <AddPersonalizationItemButton
              homeId={homeId}
              communityId={communityId}
              floorplanPersonalization
              projectId={projectId}
              onlyGeoItems
              onSubmit={false}
            />

            <AddSpecialRequestButton
              homeId={homeId}
              projectId={projectId}
              isFloorplan
            />
          </Space>
        </Row>
      )}
      <FloorplanGeoPersonalizationPanel
        readonly={itemManagementRestricted}
        homeId={homeId}
        projectId={projectId}
        onItemEdit={openItemEdit}
        onSpecialRequestEdit={openSpecialRequestEdit}
        selectedStepIds={steps?.map((x) => x.id)}
        onItemDelete={handleDelete}
        onSpecialRequestDelete={handleSpecialRequestDelete}
      />
      {steps?.map((step, index) => (
        <>
          <StepPersonalizationList
            key={index}
            itemManagementRestricted={itemManagementRestricted}
            homeId={homeId}
            communityId={communityId}
            projectId={projectId}
            step={step}
            compact={compact}
            readonly={readonly}
          />
          {index !== steps.length - 1 && <Divider />}
        </>
      ))}
      {!steps && (
        <StepPersonalizationList
          itemManagementRestricted={itemManagementRestricted}
          homeId={homeId}
          communityId={communityId}
          projectId={projectId}
          compact={compact}
          readonly={readonly}
        />
      )}
      {editItemModal}
      {editSpecialRequestModal}
    </>
  );
}
