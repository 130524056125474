import { PaymentsState, useGetPersonalizationPaymentsQuery } from '@/redux';
import { array } from '@/utils';
import { useTranslation } from 'react-i18next';
import { useGetPaymentsSubtotalAmount } from './Payments/PaymentsSubTotalInfo';
import { usePersonalizationData } from './PersonalizationDetails';
import { useGetPersonalizationItemsSubtotalAmount } from './PersonalizationItemList';
import { PersonalizationSummaryTotalInfo } from './PersonalizationSummaryTotalInfo';

interface Props {
  homeId: string;
  projectId: string;
  showBaseCost: boolean;
  showResidentPrice: boolean;
}

export function PersonalizationSummaryTotalBalance(props: Props) {
  const { homeId, projectId, showBaseCost, showResidentPrice } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'personalization.summary',
  });

  const { data: payments = array.empty<PaymentsState>() } =
    useGetPersonalizationPaymentsQuery({
      projectId,
      homeId,
    });

  const { allPersonalizations } = usePersonalizationData(props);

  const { amount: personalizationsResidentPriceAmount } =
    useGetPersonalizationItemsSubtotalAmount(
      allPersonalizations,
      (x) => x.totalResidentPrice,
      showResidentPrice,
    );
  const { amount: personalizationsBaseCostAmount } =
    useGetPersonalizationItemsSubtotalAmount(
      allPersonalizations,
      (x) => x.totalBaseCost,
      showBaseCost,
    );

  const paymentsAmount = useGetPaymentsSubtotalAmount(payments);

  const totalResidentPriceBalance =
    personalizationsResidentPriceAmount != null
      ? personalizationsResidentPriceAmount + paymentsAmount
      : undefined;
  const totalBaseCostPriceBalance =
    personalizationsBaseCostAmount != null
      ? personalizationsBaseCostAmount + paymentsAmount
      : undefined;

  return (
    <PersonalizationSummaryTotalInfo
      title={t('totalBalanceTitle')}
      baseCostTotal={totalBaseCostPriceBalance}
      residentPriceTotal={totalResidentPriceBalance}
    />
  );
}
