import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProjectStepLookupState } from '@/redux';
import { AsyncSelect, TextBox } from '@/components';
import {
  PersonalizationItemFormValue,
  PersonalizationItemDetailsModal,
} from './PersonalizationItemDetailsModal';
import styles from './PersonalizationItemDetailsModal.module.scss';
import Button, { ButtonType } from 'antd/lib/button';
import { AppstoreAddOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { useProjectStepLookupSource } from '@/views/Projects.Common';
import { useFloorplanAdd } from '..';
import classNames from 'classnames';

const { Text } = Typography;

interface Props {
  communityId: string;
  floorplanPersonalization: boolean;
  step?: ProjectStepLookupState;
  homeId: string;
  projectId: string;
  roomId?: string;
  onlyGeoItems?: boolean;
  itemId?: string;
  type?: ButtonType;
  onSubmit: ((args: PersonalizationItemFormValue) => any) | false;
  disabled?: boolean;
  name?: string;
}

const useSubmit = (
  props: Props,
  setOpenModal: (value: boolean) => any,
  stepId: string | undefined,
) => {
  const { onSubmit } = props;
  const defaultAdd = useFloorplanAdd(props.homeId, stepId, props.projectId);

  return (args: PersonalizationItemFormValue) => {
    onSubmit ? onSubmit(args) : defaultAdd(args);
    setOpenModal(false);
  };
};

export function getPersonalizationItemInitialValue(
  itemId: string | undefined,
): PersonalizationItemFormValue {
  return {
    id: null!,
    itemId: itemId || null!,
    notes: null!,
    suboptionId: null!,
    attachments: [],
  };
}

export function AddPersonalizationItemButton(props: Props) {
  const {
    communityId,
    step,
    itemId,
    type,
    onlyGeoItems,
    homeId,
    roomId,
    projectId,
    floorplanPersonalization,
    disabled,
    name,
  } = props;
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const value = getPersonalizationItemInitialValue(itemId);
  const stepsDataSource = useProjectStepLookupSource([projectId]);
  const [stepId, setStepId] = useState<string>();
  const submit = useSubmit(props, setOpenModal, stepId);

  return (
    <>
      <Button
        type={type || 'primary'}
        disabled={disabled}
        onClick={() => setOpenModal(true)}
        icon={type !== 'link' && <AppstoreAddOutlined />}
      >
        {name ? <Text strong>{name}</Text> : t('personalization.item.add')}
      </Button>
      {openModal && (
        <PersonalizationItemDetailsModal
          head={
            <div>
              <TextBox
                className={'mb-3'}
                labelClassName={classNames(!step && 'mt-2')}
                label={t('personalization.item.details.step')}
              >
                {step ? (
                  step?.name
                ) : (
                  <AsyncSelect
                    onChange={setStepId}
                    value={stepId}
                    dataSource={stepsDataSource}
                    preload
                  />
                )}
              </TextBox>

              {floorplanPersonalization && (
                <div className={styles.hint}>
                  {t('personalization.item.details.selectCategoryAndItem')}
                </div>
              )}
            </div>
          }
          communityId={communityId}
          floorplanPersonalization={floorplanPersonalization}
          initialValue={value}
          texts={{
            saveBtn: t('personalization.item.details.addButton'),
            title: t('personalization.item.details.addTitle'),
          }}
          onClose={() => setOpenModal(false)}
          onSubmit={submit}
          noItemSelection={!!itemId}
          onlyGeoItems={onlyGeoItems}
          homeId={homeId}
          projectId={projectId}
          stepId={step?.id ?? stepId}
          roomId={roomId}
          stepRequired={!step}
        />
      )}
    </>
  );
}
